import { COMMON_ERROR_MESSAGES } from '../constants/validatorConstants';
import * as Axios from 'axios';
import axiosRateLimit from 'axios-rate-limit';
import { THROTTLE_LIMIT } from '../constants/throttleLimit';

interface IValidationResult {
    valid: boolean;
    error?: Error;
}

export class ImageValidator {
    private static MAX_SIZE_BYTES = 5000000;
    private static CACHE_REFRESH_INTERVAL_MS = 150000;
    private static CONTENT_TYPE_PNG = 'image/png';
    private static CONTENT_TYPE_SVG = 'image/svg+xml';
    private static cache = new Map<string, IValidationResult>();
    public static axios = axiosRateLimit(Axios.default.create(), { maxRequests: THROTTLE_LIMIT });

    constructor() {
        // refresh cache every 15 seconds
        setInterval(ImageValidator.clearCache, ImageValidator.CACHE_REFRESH_INTERVAL_MS);
    }

    private static clearCache() {
        this.cache.clear();
    }

    private static generateKey(url: string, isIcon: boolean) {
        const salt = 'isIcon?';
        if (isIcon) return salt+url;
        return url;
    }

    private static commonImageValidation(url: string, isIcon: boolean, allowDvp: boolean) {
        return new Promise<void>((resolve, reject) => {
            const cachedResult = this.cache.get(this.generateKey(url, isIcon));
            if (!cachedResult) {
                try {
                    // tslint:disable-next-line:no-unused-expression
                    new URL(url);
                } catch (err) {
                    const regex = /\$\{[^}]+\}/;
                    if (allowDvp && regex.test(url)) {
                        resolve();
                    }
                    else {
                        const errorMsg = isIcon ? COMMON_ERROR_MESSAGES.INPUT_STRING_NOT_VALID_URL :
                        COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_LINK_NOT_VALID_URL;
                        const result = {valid: false, error: new Error(errorMsg)};
                        this.cache.set(this.generateKey(url, isIcon), result);
                        reject(result.error);
                    }
                    return;
                }
                    ImageValidator.axios.head(url)
                    .then(response => {
                        if (response.status !== 200){
                            const errorMsg = isIcon? COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_HTTP_NOT_OK :
                                COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_NOT_OPENABLE_HTTP_NOT_OK;
                            const error = new Error(errorMsg);
                            this.cache.set(this.generateKey(url, isIcon), {valid: false, error});
                            reject(error);
                            return;
                        }
                        if (response.headers['content-type'] !== this.CONTENT_TYPE_PNG && response.headers['content-type'] !== this.CONTENT_TYPE_SVG) {
                            const errorMsg = isIcon? COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_LINK_NOT_SUPPORTED_TYPE :
                                COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_LINK_NOT_SUPPORTED_TYPE;
                            const error = new Error(errorMsg);
                            this.cache.set(this.generateKey(url, isIcon), {valid: false, error});
                            reject(error);
                            return;
                        }
                        if (response.headers['content-length'] > this.MAX_SIZE_BYTES) {
                            const errorMsg = isIcon? COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_SIZE_TOO_LARGE :
                                COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_SIZE_TOO_LARGE;
                            const error = new Error(errorMsg);
                            this.cache.set(this.generateKey(url, isIcon), {valid: false, error});
                            reject(error);
                            return;
                        }
                            this.cache.set(this.generateKey(url, isIcon), {valid: true});
                            resolve();
                            return;
                    }).catch(onError => {
                        const errorMsg = isIcon? COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR :
                                COMMON_ERROR_MESSAGES.BACKGROUND_IMAGE_NOT_OPENABLE_DUE_TO_NETWORK_ERROR;
                        const error = new Error(errorMsg);
                        this.cache.set(this.generateKey(url, isIcon), {valid: false, error});
                        reject(error);
                    });
            }
            else {
                if (cachedResult.valid) {
                    resolve();
                    return;
                }
                reject(cachedResult.error);
                return;
            }
        });
    }

    /**
     * Performs basic validation on icon url
     * <nl>
     * To be valid, a url must be:
     * <ol>
     *  <li> Non-Empty and Non-Null
     *  <li> Not malformed (i.e. <code> new URL(url) </code> must not throw an error)
     *  <li> A HEAD request to the url returns HTTP 200
     *  <li> A HEAD request to the url returns the header "content-type" == "image/png"
     *  <li> A HEAD request to the url returns the header "content-length" < MAX_SIZE_BYTES
     * </ol>
     * <nl>
     * <nl>
     * <em> Caching: </em>
     * The cache map is refreshed every CACHE_REFRESH_INTERVAL_MS (15 sec).
     * Blank URLs are not cached because no real validation effort is involved.
     * Only validation results are cached.
     */
    static isIconValid(url?: string, allowDvp?: boolean) {
        if (url) {
            return this.commonImageValidation(url, true, !!allowDvp);
        }
        else {
            return new Promise<void>((resolve, reject) => {
                reject(new Error(COMMON_ERROR_MESSAGES.DOMAIN_ILLUSTRATION_LINK_CANT_BE_EMPTY));
                return;
            });
        }
    }

    /**
     * Performs basic validation on background image url
     * <nl>
     * To be valid, a url must be:
     * <ol>
     *  <li> Not malformed (i.e. <code> new URL(url) </code> must not throw an error)
     *  <li> A HEAD request to the url returns HTTP 200
     *  <li> A HEAD request to the url returns the header "content-type" == "image/png"
     *  <li> A HEAD request to the url returns the header "content-length" < MAX_SIZE_BYTES
     * </ol>
     * <nl>
     * <nl>
     * <em> Caching: </em>
     * The cache map is refreshed every CACHE_REFRESH_INTERVAL_MS (15 sec).
     * Blank URLs are not cached because no real validation effort is involved.
     * Only validation results are cached.
     */
    static isBackgroundImageValid(url?: string, allowDvp?: boolean) {
        if (url) {
            return this.commonImageValidation(url, false, !!allowDvp);
        }
        else {
            return new Promise<void>((resolve, reject) => {
                resolve();
                return;
            });
        }
    }
}