import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from '../reducers/index';

import { Wizard, Flashbar, Flash } from '@amzn/awsui-components-react';

import { getLinkableUrl, PAGE } from '../constants/page';
import { pushMetadataAction, pushTypeAction, createExperienceAction, createOdysseyExperienceAction, pushMobileHomeCardContent } from '../actions/experienceEditViewActions';
import { enableCreatingAction, pushRegionSectionAction, createInflightExperienceAction } from '../actions/experienceEditViewActions';
import { MOBILE_HOME_CARD_USER_GROUP, PROACTIVE_NOTIFICATIONS_USER_GROUP, RCP_CREATORS_USER_GROUP, FRICTION_TRIGGERED_CIF_USER_GROUP } from '../constants/userGroups';
import { ExperienceUpdateCandidate } from '../models/ExperienceUpdateCandidate';
import { ExperienceType } from '../models/Experience';

import { AppNotificationWorkflow } from './workflow/AppNotificationWorkflow';
import { DeviceNotificationWorkflow } from './workflow/DeviceNotificationWorkflow';
import { VisualCIFWorkflow } from './workflow/VisualCIFWorkflow';

import { BeforeGettingStartedSectionView } from '../components/detail/creation/BeforeGettingStartedSectionView';
import { ExperienceTypeSectionView } from '../components/detail/creation/ExperienceTypeSectionView';
import ExperienceInfoSectionView from './sections/ExperienceInfoSectionView';
import ExperienceTrackingSectionView from './sections/ExperienceTrackingSectionView';
import { DEFAULT_MARKETPLACE } from '../constants/marketplaces';
import { DEFAULT_LOCALE } from '../constants/locales';
import { getPermissionAction } from '../actions/authenticationActions';
import { BasicCIFWorkflow, ExperienceWorkflow } from './workflow/ExperienceWorkflow';
import { CLEAR_CAPABILITY_SEARCH } from '../constants/capabilitySearchActionTypes';
import { MobileHomeCardWorkflow } from './workflow/MobileHomeCardWorkflow';
import { RCPExperienceTrackingSectionView } from './sections/RCPExperienceTrackingSectionView';
import { RotatingContentPanelWorkflow } from './workflow/RotatingContentPanelWorkflow';
import { TemplateType } from '../models/mobileHomeCard/MobileHomeCardContent';
import MobileHomeCardAbExperimentSectionView from './sections/MobileHomeCardAbExperimentSectionView';
import _ from 'lodash';
import { isInvalidFrictionTrigger } from './sections/NluOpportunityTriggeringSectionView';

// Will be passed to the ExperienceUpdater to identify
// this experience was created using the new CREATION
// WORKFLOW.
const NEW_CREATION_WORKFLOW: boolean = true;

interface IExperienceCreationWorkflowProps {
    dispatch: any;

    // from edit
    isEditing: boolean;
    isUpdating: boolean;
    updateError?: Error;
    updateCandidate?: ExperienceUpdateCandidate;

    userAlias: string;
    permissions?: string[];
    mhcPermissions?: string[];
    rcpPermissions?: string[];
    shouldDisableSave: boolean;
}

interface IExperienceCreationWorkflowState {
    disableTypeSelection: boolean;

    activeStepIndex: number;
    createValidationError?: string;
    disableNextButton: boolean;
    maxStepsAvailable: number;

    enableVoiceCif?: boolean;
    content?: string;
}

const i18nStrings = {
    stepNumberLabel: (stepNumber: number) => `Step ${stepNumber}`,
    collapsedStepsLabel: (stepNumber: number, stepsCount: number) =>
        `Step ${stepNumber} of ${stepsCount}`,
    cancelButton: 'Cancel',
    previousButton: 'Previous',
    nextButton: 'Next',
    submitButton: 'Create experience',
    optional: 'optional'
};

export class ExperienceCreationWorkflow extends React.Component<
    IExperienceCreationWorkflowProps,
    IExperienceCreationWorkflowState
>{
    constructor(props: IExperienceCreationWorkflowProps) {
        super(props);

        this.state = {
            activeStepIndex: 0,
            disableTypeSelection: false,
            disableNextButton: false,
            maxStepsAvailable: 3
        };
    }

    public componentDidMount() {
        const { dispatch } = this.props;

        dispatch(enableCreatingAction());

        dispatch(getPermissionAction(MOBILE_HOME_CARD_USER_GROUP));
        dispatch(getPermissionAction(PROACTIVE_NOTIFICATIONS_USER_GROUP));
        dispatch(getPermissionAction(RCP_CREATORS_USER_GROUP));
        dispatch(getPermissionAction(FRICTION_TRIGGERED_CIF_USER_GROUP));
    }

    public componentDidUpdate() {
        const { dispatch, updateCandidate } = this.props;

        if (!updateCandidate) {
            dispatch(enableCreatingAction());
        }
    }

    private updateType(type: string) {
        const { dispatch } = this.props;
        let isQuickCreate = false;
        let isVisualCIF = false;

        switch (type) {
            case 'BasicCIF':
                type = 'CIF';
                isQuickCreate = true;
                isVisualCIF = false;
                break;
            case 'VisualCIF':
                type = 'CIF';
                isQuickCreate = false;
                isVisualCIF = true;
                break;
            default:
                isQuickCreate = false;
                isVisualCIF = false;
        }

        dispatch(pushTypeAction(type as ExperienceType, isQuickCreate, isVisualCIF));

        // Update max step available based on type
        this.setState({
            maxStepsAvailable: (type === 'CIF') ? 3 : 5
        });

        this.setState({
            maxStepsAvailable: (type === 'AppNotification') ? 3 : 5
        });
    }

    private onExperienceTitleUpdate(title?: string) {
        this.setState({ disableNextButton: true });
        if (title && title.length !== 0) {
            this.setState({ disableNextButton: false });
        }
    }

    private onRcpExperienceTitleUpdate(title?: string) {
        this.setState({ disableNextButton: true });
        if (title && title.length !== 0 &&
            !_.isEmpty(this.props.updateCandidate?.getImpressionTracking().weblab) &&
            !_.isEmpty(this.props.updateCandidate?.getBasicInfo().groupImpressions)) {
            this.setState({ disableNextButton: false });
        }
    }

    private onRcpExperienceWeblabUpdate(weblab?: string) {
        this.setState({ disableNextButton: true });
        if (weblab && weblab.length !== 0 &&
            !_.isEmpty(this.props.updateCandidate?.getBasicInfo().title) &&
            !_.isEmpty(this.props.updateCandidate?.getBasicInfo().groupImpressions)) {
            this.setState({disableNextButton: false});
        }
    }

    private onRcpExperienceOperatorGroupUpdate(operatorGroup?: string) {
        this.setState({ disableNextButton: true });
        if (operatorGroup && operatorGroup.length !== 0 &&
            !_.isEmpty(this.props.updateCandidate?.getImpressionTracking().weblab) &&
            !_.isEmpty(this.props.updateCandidate?.getBasicInfo().title)) {
            this.setState({disableNextButton: false});
        }
    }

    public onContentCXGuidelinesCheckedUpdate(checked?: boolean) {
        this.setState({ disableNextButton: true });
        if (checked) {
            this.setState({ disableNextButton: false });
        }
    }

    public onMobileAppGuidelinesCheckedUpdate(checked?: boolean) {
        this.setState({disableNextButton: true});
        if (checked) {
            this.setState({disableNextButton: false});
        }
        }

    public onEnableVoiceCifCheckedUpdate(checked?: boolean) {
        const { updateCandidate } = this.props;
        this.setState({ disableNextButton: false });
        if (checked && !updateCandidate?.getContent().checkCXGuidelines) {
            this.setState({ disableNextButton: true });
        }
    }

    public onCardActionAndCharLimitCheckedUpdate(isCardActionVerified?: boolean, isCharLengthExceedLimit?: boolean) {
        this.setState({ disableNextButton: true });
        if (isCardActionVerified && !isCharLengthExceedLimit) {
            this.setState({ disableNextButton: false });
        }
    }

    public onCardConfigurationUpdate(configured?: boolean) {
        this.setState({ disableNextButton: true });
        if (!configured) {
            this.setState({ disableNextButton: false });
        }
    }

    private updateRegion(marketplace?: string, locale?: string) {
        const { dispatch } = this.props;
        dispatch(pushRegionSectionAction(marketplace, locale));
    }

    private updateMhcTemplateType(templateType: string, section: string) {
        const { dispatch } = this.props;
        dispatch(pushMobileHomeCardContent({
            templateType: templateType as TemplateType,
            section
        }));
    }

    // For the duration of the workflow we will have an inflight experience
    // to help with staging a dummy experience that can be edited by the
    // appropriate sections and components.
    private createInflightExperience() {
        const { dispatch, updateCandidate } = this.props;
        if (updateCandidate) {
            // For experiment type CIF we need to default marketplace and locale
            // while for all other experiment Types we need to only default the locale.
            (updateCandidate.getType() === 'CIF' || updateCandidate.getType() === 'MobileHomeCard')
                ? this.updateRegion(DEFAULT_MARKETPLACE, DEFAULT_LOCALE)
                : this.updateRegion(undefined, DEFAULT_LOCALE);

            // Push meta data for inflight experience
            this.updateMetadata();

            // for MHC, we need default template Type set to HeroTemplate and
            // section set to discover more
            if (updateCandidate.getType() === 'MobileHomeCard') {
                this.updateMhcTemplateType('HeroTemplate', 'DiscoverMore');
            }

            // We need an inflight experience to exist IFF we want all editable
            // section(s) to function as normal.
            dispatch(createInflightExperienceAction());
        }
    }

    private updateMetadata() {
        const { dispatch, userAlias } = this.props;
        dispatch(pushMetadataAction({
            createdAt: Date.now(),
            modifiedAt: Date.now(),
            createdBy: userAlias,
            modifiedBy: userAlias,
            changedStateAt: Date.now(),
        }));
    }

    private createButtonClickHandler() {
        const { dispatch, updateCandidate } = this.props;

        if (updateCandidate) {

            // Update Metadata one last time before creation.
            this.updateMetadata();

            // mobile home card use new management api
            if (updateCandidate.getType() === 'MobileHomeCard' || updateCandidate.getType() === 'RotatingContentPanel') {
                dispatch(createOdysseyExperienceAction());
            } else {
                dispatch(createExperienceAction(NEW_CREATION_WORKFLOW));
            }

            // Reset incase creation fails and we need to address issues
            this.setState({
                createValidationError: undefined
            });
        }
    }

    // Need to wire-up a "Transaction validation" hook to ensure all felids
    // on the current workflow are valid prior to continuing.
    private onNextButtonHandler(event: CustomEvent<Wizard.StepClickDetail>) {
        const { updateCandidate, dispatch } = this.props;
        // After the first page disable next button until user enters a title
        if (event.detail.requestedStepIndex === 1) {
            this.setState({ disableNextButton: true });
            this.setState({ content: 'Please specify the experience type and provide a title to continue' });
        }
        // After selecting the appropriate Type we no longer allow the user to
        // change this type for the rest of the workflow. (Magic number refers
        // to the second element in the workflow, we count up from 1 here.)
        if (event.detail.requestedStepIndex === 2) {
            if (!_.isEmpty(updateCandidate?.getBasicInfo().groupImpressions)) {
                dispatch(getPermissionAction(updateCandidate?.getBasicInfo().groupImpressions));
            }

            this.createInflightExperience();
            this.setState({ disableTypeSelection: true });
            this.setState({ activeStepIndex: this.state.maxStepsAvailable });
            if (updateCandidate?.getType() === 'CIF' && updateCandidate.getIsQuickCreateWorkflow()) {
                this.setState({ disableNextButton: true });
            }
        }
        if (updateCandidate && updateCandidate.getType() === 'CIF' && updateCandidate.getIsVisualCIF() && event.detail.requestedStepIndex === 3) {
            this.setState({ disableNextButton: true });
            this.setState({ content: 'Complete configuration or remove the card' });
        }
        if (updateCandidate && updateCandidate.getType() === 'CIF' && !updateCandidate.getIsVisualCIF() && event.detail.requestedStepIndex === 4) {
            this.setState({ disableNextButton: true });
        }

        // confirm checkbox on step 4 is required to move forward
        if (updateCandidate && updateCandidate.getType() === 'MobileHomeCard' && event.detail.requestedStepIndex === 4) {
            this.setState({ disableNextButton: true });
            this.setState({ content: 'Please confirm the checkbox below to move on' });
        }

        if (updateCandidate && updateCandidate.getType() === 'AppNotification' && event.detail.requestedStepIndex === 4) {
            this.setState({disableNextButton: true});
        }

        // Keep track of furthest step reached in the workflow.
        this.setState({ activeStepIndex: event.detail.requestedStepIndex });
    }

    private onStepNavigationHandler(event: CustomEvent<Wizard.StepClickDetail>) {
        const { updateCandidate } = this.props;
        // Keep track of furthest step reached in the workflow.
        this.setState({ activeStepIndex: event.detail.requestedStepIndex });
        if (updateCandidate && updateCandidate.getType() === 'CIF' && event.detail.requestedStepIndex !== 4) {
            this.setState({ disableNextButton: false });
        }
        if (updateCandidate && updateCandidate.getType() === 'AppNotification' && event.detail.requestedStepIndex !== 4) {
            this.setState({disableNextButton: false});
        }
    }

    private onPreviousButtonHandler(event: CustomEvent<Wizard.StepClickDetail>) {
        const { updateCandidate } = this.props;
        // Keep track of furthest step reached in the workflow.
        this.setState({ activeStepIndex: event.detail.requestedStepIndex });
        if (updateCandidate && updateCandidate.getType() === 'CIF' && event.detail.requestedStepIndex < 4) {
            this.setState({ disableNextButton: false });
        }

        // in case click prev on step 4 and enable next button
        if (updateCandidate && updateCandidate.getType() === 'MobileHomeCard' && event.detail.requestedStepIndex < 4) {
            this.setState({ disableNextButton: false });
        }

        if (updateCandidate && updateCandidate.getType() === 'AppNotification' && event.detail.requestedStepIndex < 4) {
            this.setState({ disableNextButton: false });
        }
    }

    private cancelButtonClickHandler() {
        const { dispatch } = this.props;

        dispatch({
            type: CLEAR_CAPABILITY_SEARCH
        });

        window.location.assign(getLinkableUrl(PAGE.Home));
    }

    render() {
        const { updateCandidate, isUpdating, updateError, permissions, mhcPermissions, rcpPermissions, userAlias, shouldDisableSave} = this.props;

        if (isUpdating) {
            return <Flash dismissible={false} >Creating...</Flash>;
        }

        const begin: Wizard.Step = {
            title: 'Before you get started',
            content: <BeforeGettingStartedSectionView userAcceptedAgreement={false} />
        };

        const isAdmin = (permissions && permissions.includes('ADMIN')) ? true : false;
        const isRcp = updateCandidate?.getType() === 'RotatingContentPanel';
        const isRcpExpConfigured = (!_.isEmpty(updateCandidate?.getBasicInfo().title)) &&
                (!_.isEmpty(updateCandidate?.getImpressionTracking().weblab)) &&
                (!_.isEmpty(updateCandidate?.getBasicInfo().groupImpressions)) &&
                (typeof updateCandidate?.getBullseye() !== 'undefined' && updateCandidate?.getBullseye()) &&
                (!_.isEmpty(updateCandidate?.getRegion().locale)) &&
                (!_.isEmpty(updateCandidate?.getRotatingContentPanelContent()?.rcpMetaData?.intent)) &&
                (!_.isEmpty(updateCandidate?.getRegion().marketplace)) &&
                (!_.isEmpty(updateCandidate?.getRotatingContentPanelSchedulingConstraint()?.startDate)) &&
                (!_.isEmpty(updateCandidate?.getRotatingContentPanelSchedulingConstraint()?.endDate));

        const selectExperience: Wizard.Step = {
            title: 'Experience setup',
            content: <div>
                <ExperienceTypeSectionView
                    selectedExperienceType={updateCandidate?.getType()}
                    disableTypeSelection={this.state.disableTypeSelection}
                    onExperienceChange={(e) => this.updateType(e)}
                    permissions={permissions}
                    mhcPermissions={mhcPermissions}
                    rcpPermissions={rcpPermissions}
                    userAlias={userAlias}
                    isQuickCreate={updateCandidate?.getIsQuickCreateWorkflow()}
                    isVisualCIF={updateCandidate?.getIsVisualCIF()} />
                <ExperienceInfoSectionView
                    selectedExperienceType={updateCandidate?.getType()}
                    onExperienceTitleChange={(e) => (isRcp) ? this.onRcpExperienceTitleUpdate(e) : this.onExperienceTitleUpdate(e)}
                    onExperienceOperatorGroupChange={(e) => (isRcp) ? this.onRcpExperienceOperatorGroupUpdate(e) : undefined}/>
                {isRcp && <RCPExperienceTrackingSectionView onRcpExperienceWeblabUpdate={(e) => this.onRcpExperienceWeblabUpdate(e)} />}
                {updateCandidate?.getType() === 'MobileHomeCard' && <MobileHomeCardAbExperimentSectionView />}
                {(updateCandidate?.getType() !== 'MobileHomeCard' && !isRcp) && <ExperienceTrackingSectionView
                    selectedExperienceType={updateCandidate?.getType()} />}
            </div>
        };

        let typeSteps: Wizard.Step[] = [];
        switch (updateCandidate?.getType()) {
            case 'CIF':
                if (updateCandidate?.getIsQuickCreateWorkflow()) {
                    typeSteps = BasicCIFWorkflow(isAdmin, (checked) => this.onContentCXGuidelinesCheckedUpdate(checked));
                    break;
                } else if (updateCandidate?.getIsVisualCIF()) {
                    typeSteps = VisualCIFWorkflow(
                        isAdmin,
                        (checked) => this.onContentCXGuidelinesCheckedUpdate(checked),
                        (checked) => this.onEnableVoiceCifCheckedUpdate(checked),
                        (configured) => this.onCardConfigurationUpdate(configured)
                    );
                    break;
                }
                typeSteps = ExperienceWorkflow(permissions, (checked) => this.onContentCXGuidelinesCheckedUpdate(checked));
                break;
            case 'AppNotification':
                typeSteps = AppNotificationWorkflow((checked) => this.onMobileAppGuidelinesCheckedUpdate(checked));
                break;
            case 'DeviceNotification':
                typeSteps = DeviceNotificationWorkflow;
                break;
            case 'MobileHomeCard':
                typeSteps = MobileHomeCardWorkflow((isCardActionVerified, isCharLengthExceedLimit) => this.onCardActionAndCharLimitCheckedUpdate(isCardActionVerified, isCharLengthExceedLimit));
                break;
            case 'RotatingContentPanel':
                typeSteps = RotatingContentPanelWorkflow(permissions);
                break;
        }

        if (updateCandidate && updateCandidate.getType() === 'CIF' && updateCandidate.getOpportunityTriggerInclusions()) {
            const shouldDisableNext = isInvalidFrictionTrigger(updateCandidate.getOpportunityTriggerInclusions());
            if (this.state.disableNextButton !== shouldDisableNext) {
                this.setState({ disableNextButton: shouldDisableNext, content: 'Friction trigger is invalid.' });
            }
        }

        // All experiences require Begin and SelectExperience, followed by Type specific elements.
        const steps: Wizard.Step[] = [begin, selectExperience].concat(typeSteps);

        return <div>
            {updateError && <Flashbar
                id='flashbar.update-error'
                items={[{
                    header: 'Create failed',
                    content: updateError.message !== 'Network Error' ? updateError.message : 'Failed to create',
                    type: 'error'
            }]} />}
            {this.state.disableNextButton && <Flashbar items={[{
                content: this.state.content,
                type: 'error',
                dismissible: true
            }]} />}
            <Flashbar items={[{
                header: 'Note',
                content: 'To save your experience, you will need to go through the whole workflow and click "Create Experience" on the Review page',
                type: 'info',
                dismissible: true
            }]} />
            <div className='awsui-util-ml-xl awsui-util-mb-xl awsui-util-mr-xl'>
                <Wizard
                    id='wizard.create-experience'
                    steps={steps}
                    activeStepIndex={this.state.activeStepIndex}
                    i18nStrings={i18nStrings}
                    onNextButtonClick={this.onNextButtonHandler.bind(this)}
                    onPreviousButtonClick={this.onPreviousButtonHandler.bind(this)}
                    onCancelButtonClick={this.cancelButtonClickHandler.bind(this)}
                    onSubmitButtonClick={this.createButtonClickHandler.bind(this)}
                    onStepNavigationClick={this.onStepNavigationHandler.bind(this)}
                    isLoadingNextStep={this.state.disableNextButton || (isRcp && !isRcpExpConfigured && this.state.activeStepIndex === 4) || shouldDisableSave} />
            </div>
        </div>;
    }
}

const mapStateToProps = ({ experienceEditViewState, navigationViewState, authenticationState }: AppState) => {
    return {
        isEditing: experienceEditViewState.isEditing,
        isCloning: experienceEditViewState.isCloning,
        isUpdating: experienceEditViewState.isUpdating,
        updateError: experienceEditViewState.error || undefined,
        updateCandidate: experienceEditViewState.updateCandidate || undefined,

        userAlias: navigationViewState.userAlias || 'nobody',
        permissions: authenticationState.permissions || [],
        mhcPermissions: authenticationState.mhcPermissions || [],
        rcpPermissions: authenticationState.rcpPermissions || [],
        shouldDisableSave: experienceEditViewState.shouldDisableSave
    };
};

export default connect(mapStateToProps)(ExperienceCreationWorkflow);
