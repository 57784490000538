export interface INotificationExperienceContent {
    title?: string;
    displayContent?: string;
    spokenContent?: string;
    url?: string;
    appNotificationType?: string;
    ctaButtonTitle?: string;
    ctaButtonAction?: string;
    checkAppMobileNotificationsGuidelines?: boolean;
    interactionPayload?: string;
    notificationThumbnail?: string;
    banyanCampaign?: boolean;
}

export function appExperienceContentEmpty(content: INotificationExperienceContent): boolean {
    return (!content.title || !content.displayContent || !content.url);
}

export function deviceExperienceContentEmpty(content: INotificationExperienceContent): boolean {
    return (!content.title || !content.displayContent || !content.spokenContent);
}
